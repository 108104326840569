





























import { Observer } from "mobx-vue";
import { Component, Vue, Provide, Watch, Inject } from "vue-property-decorator";
import { walletStore } from "@/stores/wallet-store";

@Observer
@Component({})
export default class StakingLPDialog extends Vue {
  walletStore = walletStore;
  downloadMetamask() {
    window.open("https://metamask.io/download", "_blank");
  }
}
